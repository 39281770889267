import React, { useEffect, useState } from "react";
import client from "../../utils/API";
import WjHtml from "../shared/WjHtml";
import { Typography, Container, Box } from "@material-ui/core";
import WjSlider from "../shared/WjSlider";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      float: "none"
    }
  },
  title: {
    marginBottom: theme.spacing(5)
  }
}));

const Page = ({ match, contentType = "seite" }) => {
  const [page, setPage] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const slug = match.params.slug;
    if (!slug) {
      return;
    }
    console.log(match.params.slug);
    fetchPage(slug);
  }, [match.params.slug]);

  const fetchPage = slug => {
    client
      .items()
      .type(contentType)
      .equalsFilter("elements.slug", slug)
      .toObservable()
      .subscribe(response => {
        setPage(response.items ? response.items[0] : null);
      });
  };

  if (!page) {
    return null;
  }

  console.log(page);

  return (
    <>
      <Container maxWidth="lg">
        <Box pt={5} pb={10}>
          <Typography variant="h1" className={classes.title}>
            {page.titel.value}
          </Typography>
          {Boolean(page.bilder && page.bilder.value.length) && (
            <Box
              width={{ xs: "100%", sm: "50%" }}
              mr={{ xs: 0, sm: 3 }}
              mb={{ xs: 4, sm: 3 }}
              className={classes.sliderWrapper}
            >
              <WjSlider images={page.bilder.value.map(b => b.url)} />
            </Box>
          )}
          <WjHtml>{page.text.value}</WjHtml>
        </Box>
      </Container>
    </>
  );
};

export default Page;
