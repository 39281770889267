import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import CalendarIcon from "mdi-react/CalendarIcon";
import Moment from "react-moment";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%"
  },
  media: {
    height: 0,
    paddingTop: "80%" // 16:9
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  }
}));

const WjTermin = ({ item }) => {
  const classes = useStyles();

  if (!item) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <CalendarIcon />
          </Avatar>
        }
        title="Nächster Termin"
        subheader="der WJ Kaiserslautern"
      />
      {Boolean(item.bild.value) && (
        <CardMedia className={classes.media} image={item.bild.value[0].url} />
      )}

      <CardContent>
        <Typography variant="h3" color="textSecondary" gutterBottom>
          {item.titel.value}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Moment format={"DD.MM.YYYY"}>{item.datum.value}</Moment>
          {Boolean(item.uhrzeit.value) && " - " + item.uhrzeit.value + " Uhr"}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          color="primary"
          component="a"
          href="https://www.facebook.com/pg/WJKaiserslautern/events/?ref=page_internal"
        >
          mehr
        </Button>
      </CardActions>
    </Card>
  );
};

export default WjTermin;
