import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { grey } from "@material-ui/core/colors";

export const THEME = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#243a6e",
        dark: "#1d3265"
      },
      secondary: {
        main: grey[500]
      },
      background: {
        paper: "#fff",
        default: grey[50]
      }
    },
    typography: {
      fontFamily: "Roboto",
      h1: {
        fontWeight: 400,
        fontSize: "3rem"
      },
      h2: {
        fontWeight: 300,
        fontSize: "3rem"
      },
      h3: {
        fontWeight: 700,
        fontSize: "1.6rem"
      },
      h4: {
        fontWeight: 500,
        fontSize: "1.7rem"
      },
      overline: {
        letterSpacing: 1
      },
      caption: {
        fontWeight: 500,
        fontSize: "0.9rem",
        color: grey[600]
      },
      body1: {
        fontSize: "1.1rem"
      }
    },
    shape: {
      borderRadius: 0
    },
    status: {
      danger: "orange"
    },
    overrides: {},
    props: {
      MuiButton: {
        disableFocusRipple: true
      },
      MuiTab: {
        disableFocusRipple: true
      },
      MuiIconButton: {
        disableFocusRipple: true
      }
    }
  })
);
