import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  MenuList,
  MenuItem,
  Button
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useScreensize from "../hooks/useScreensize";
import { grey } from "@material-ui/core/colors";
import client from "../utils/API";
import { SortOrder } from "@kentico/kontent-delivery";

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: "auto",
    backgroundColor: grey[50],
    position: "relative",
    zIndex: 2,
    color: grey[500]
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
      justifyContent: "center"
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2)
    }
  },
  footerLink: {
    margin: theme.spacing(1, 0),
    color: "white",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2)
    }
  },
  phoneIcon: {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24
  },
  title: {
    opacity: 0.7,
    marginBottom: theme.spacing(2)
  },
  menuItem: {
    height: 30,
    minHeight: 30,
    fontSize: "1rem",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  logo: {
    height: 60,
    width: "auto",
    margin: theme.spacing(0, 2)
  }
}));

const Footer = ({ settings }) => {
  const classes = useStyles();
  const [logos, setLogos] = useState(null);

  useEffect(() => {
    fetchFooterLogos();
  }, []);

  const fetchFooterLogos = () => {
    client
      .items()
      .type("footer_logo")
      .orderParameter("elements.position", SortOrder.asc)
      .toObservable()
      .subscribe(response => {
        setLogos(response.items ? response.items : null);
      });
  };

  const footerMenu = (
    <>
      {[
        { title: "Impressum", url: "/impressum" },
        { title: "Datenschutzerklärung", url: "/datenschutz" }
      ].map((item, index) => (
        <Button
          size="small"
          className={clsx(classes.menuItem)}
          color="primary"
          key={index}
          component={Link}
          to={item.url}
        >
          {item.title}
        </Button>
      ))}
    </>
  );

  return (
    <>
      <footer className={classes.footer}>
        <Box bgcolor="white" py={4}>
          <Container maxWidth="lg">
            <Box mt={{ xs: 4, lg: 0 }}>
              <Box ml={-2}>{footerMenu}</Box>
            </Box>
          </Container>
        </Box>
        <Box bgcolor={grey[500]} py={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            mt={{ xs: 4, lg: 0 }}
          >
            {Boolean(logos) &&
              logos.map((logo, index) => (
                <a key={index} href={logo.url.value} target="_blank">
                  <img
                    src={logo.logo.value[0].url}
                    alt=""
                    className={classes.logo}
                  />
                </a>
              ))}
          </Box>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
