import React, { useEffect, useRef } from "react";
import { ThemeProvider } from "@material-ui/styles";
import * as constants from "./constants";
import { CssBaseline } from "@material-ui/core";
import Layout from "./components/Layout";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/pages/Home";
import Page from "./components/pages/Page";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import "react-image-lightbox/style.css";
import ScrollMemory from "react-router-scroll-memory";

function App() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  return (
    <BrowserRouter>
      <ScrollMemory />
      <ThemeProvider theme={constants.THEME}>
        <CssBaseline />
        <Layout refs={[ref1, ref2]}>
          <Switch>
            <Route
              exact
              path="/"
              render={props => <Home {...props} refs={[ref1, ref2]} />}
            />
            <Route exact path="/:slug" component={Page} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
