import React from "react";
import parse from "html-react-parser";
import { Typography } from "@material-ui/core";

const defaultReplaceMapping = [
  { tag: "a", variant: "body1", component: "a", className: "contentLink" },
  { tag: "p", variant: "body1", component: "p", className: "" },
  { tag: "h1", variant: "h3", component: "h1", className: "" },
  { tag: "h2", variant: "h4", component: "h2", className: "" },
  { tag: "h3", variant: "h5", component: "h3", className: "" },
  { tag: "h4", variant: "h6", component: "h4", className: "" }
];

let replaceCount = 0;

const replace = (domNode, replaceMapping, isTeaser) => {
  if (isTeaser && domNode.type === "tag" && domNode.prev !== null) {
    return <React.Fragment />;
  }
  replaceCount++;
  if (domNode.type && domNode.type === "tag") {
    const mapping = replaceMapping.filter(f => f.tag === domNode.name)[0];
    if (mapping) {
      return (
        <Typography
          gutterBottom
          key={replaceCount}
          variant={mapping.variant}
          component={mapping.component}
          className={mapping.className}
          href={
            domNode.attribs && domNode.attribs["href"]
              ? domNode.attribs["href"]
              : undefined
          }
          target={
            domNode.attribs &&
            domNode.attribs["href"] &&
            domNode.attribs["href"].indexOf(".pdf") !== -1
              ? "_blank"
              : undefined
          }
        >
          {domNode.children.map(child =>
            replace(child, replaceMapping, isTeaser)
          )}
        </Typography>
      );
    } else if (domNode.name === "br") {
      return <br key={replaceCount} />;
    } else {
      return parse(
        `<${domNode.name} key="${replaceCount}">${domNode.children.map(child =>
          replace(child, replaceMapping)
        )}</${domNode.name}>`
      );
    }
  }
  if (domNode.type && domNode.type === "text") {
    let out = domNode.data;
    if (isTeaser) {
      out = out
        .split(" ")
        .slice(0, 30)
        .join(" ");
    }
    return out;
  }
};

const WjHtml = ({
  children,
  replaceMapping = defaultReplaceMapping,
  teaser = false
}) => {
  return (
    <>
      {parse(children, {
        replace: domNode => replace(domNode, replaceMapping, teaser)
      })}
    </>
  );
};

export default WjHtml;
