import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Button,
  Toolbar,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  Container,
  Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useTheme } from "@material-ui/styles";
import WjSeo from "./shared/WjSeo";
import useScreensize from "../hooks/useScreensize";
import MenuUpIcon from "mdi-react/MenuUpIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import ScreenSize from "../utils/ScreenSize";
import Logo from "../assets/logo.png";

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      zIndex: 9999999
    }
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    zIndex: 999
  },
  menuButton: {
    color: blueGrey[800],
    [theme.breakpoints.up("lg")]: {
      display: "none",
      marginRight: theme.spacing(1)
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 9999,
    width: drawerWidth,
    background: "white",
    color: grey[600]
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  logo: {
    height: "80%",
    width: "auto"
  },
  logoWrapper: {
    height: "100%",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: "auto"
    }
  },
  appBarMenu: {
    flexGrow: 1
  },
  tb: {
    height: 1,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  btnBeratung: {
    marginRight: "15px"
  },
  desktopMenuIcon: {
    marginLeft: "auto",
    marginRight: theme.spacing(2)
  },
  popover: {
    zIndex: 99999999,
    marginTop: theme.spacing(3)
  },
  popoverPaper: {
    background: "white",
    minWidth: 600
  },
  menuUpIcon: {
    position: "absolute",
    right: 0,
    top: -30,
    width: 50,
    height: 50,
    color: "white"
  },
  menuImage: {
    backgroundSize: "cover"
  },
  menuItem: {
    borderRadius: 4
  },
  activeMenuItem: {
    background: grey[100]
  },
  menuItemButton: {
    fontWeight: 600,
    minWidth: 0,
    margin: theme.spacing(0, 3)
  },
  drawerLogo: {
    height: "auto",
    width: 160
  }
}));

let scrollInterval;
const scrollToRef = ref => {
  const scrollDuration = 300;
  const scrollDestination = ref.current.getBoundingClientRect().top;
  const scrollStep = Math.floor(scrollDestination / (scrollDuration / 10));
  console.log(scrollDestination, scrollStep, window.pageYOffset);
  setTimeout(() => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
    }
  }, 300);
  scrollInterval = setInterval(function() {
    console.log(window.scrollY, scrollDestination);
    //if (window.scrollY < scrollDestination) {
    window.scrollBy(0, scrollStep);
    //} else {
    // console.log("Ready");
    // clearInterval(scrollInterval);
    //}
  }, 10);
};

const Layout = ({ children, refs }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const { xs, sm, md, lg, xl } = useScreensize();
  const [activePath, setActivePath] = useState(null);
  const location = useLocation();

  const menuItems = [
    {
      title: "Wer wir sind",
      href: "/#wer-wir-sind"
    },
    {
      title: "Was wir tun",
      href: "/#was-wir-tun"
      // action: () => {
      //   executeScroll(refs[1]);
      // }
    },
    { title: "Mitglied werden", path: "/mitglied-werden" },
    { title: "Kontakt", path: "/kontakt" },
    {
      title: "Veranstaltungen",
      href: "/veranstaltungen"
    }
  ];

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const executeScroll = ref => scrollToRef(ref);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDesktopClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDesktopOpen(false);
  };

  const menu = (
    <MenuList>
      {menuItems.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.path === activePath
          })}
          button
          key={index}
          component={item.action ? undefined : item.href ? "a" : Link}
          to={item.action || item.href ? undefined : item.path}
          href={item.href ? item.href : undefined}
          onClick={event => {
            setMobileOpen(false);
            if (item.action) {
              item.action();
            }
          }}
        >
          {item.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const secondaryMenu = Boolean(settings) && (
    <MenuList>
      {settings.secondary_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.value.url === activePath
          })}
          button
          key={index}
          component={Link}
          to={item.value.url}
          onClick={event => {
            setMobileOpen(false);
            handleDesktopClose(event);
          }}
        >
          {item.value.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const drawer = (
    <div>
      <a href="/">
        <Box p={2}>
          <img src={Logo} alt="" className={classes.drawerLogo} />
        </Box>
      </a>
      <Divider />
      {menu}
      <Divider />
      {secondaryMenu}
    </div>
  );

  const seo = Boolean(settings) && (
    <WjSeo title={settings.title} description={settings.description} />
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white" }}
        className={classes.appBar}
      >
        <Toolbar className={classes.tb}>
          <Container maxWidth="lg">
            <Box display="flex" height={xs ? 56 : 64} alignItems="center">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Link className={classes.logoWrapper} to="/">
                <img src={Logo} alt="" className={classes.logo} />
              </Link>
              <Hidden mdDown>
                {menuItems.map((item, index) => (
                  <Button
                    key={index}
                    component={item.action ? undefined : item.href ? "a" : Link}
                    to={item.action || item.href ? undefined : item.path}
                    href={item.href ? item.href : undefined}
                    color="primary"
                    className={classes.menuItemButton}
                    onClick={() => {
                      if (item.action) {
                        item.action();
                      }
                    }}
                  >
                    {item.title}
                  </Button>
                ))}
              </Hidden>
              <IconButton
                component="a"
                href="https://www.facebook.com/WJKaiserslautern/"
              >
                <FacebookIcon />
              </IconButton>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      {desktopOpen && (
        <Popper
          className={classes.popover}
          open={desktopOpen}
          anchorEl={anchorRef.current}
          keepMounted
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} in={desktopOpen}>
              <Paper id="menu-list-grow" className={classes.popoverPaper}>
                <MenuUpIcon className={classes.menuUpIcon} />
                <ClickAwayListener onClickAway={handleDesktopClose}>
                  <Box display="flex" p={4}>
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" mr={3}>
                      {menu || <></>}
                    </Box>
                    <Box width="1px" bgcolor={grey[200]} mr={3} />
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" px={2}>
                      {secondaryMenu || <></>}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      <Footer settings={settings} />
      {seo}
    </div>
  );
};

export default Layout;
