import React, { useState, useEffect } from "react";
import client from "../../utils/API";
import WjSlider from "../shared/WjSlider";
import { Box, Container, Typography, Paper, Button } from "@material-ui/core";
import { makeStyles, getThemeProps, useTheme } from "@material-ui/styles";
import { SortOrder } from "@kentico/kontent-delivery";
import moment from "moment";
import WjTermin from "../shared/WjTermin";
import WjHtml from "../shared/WjHtml";
import PlusIcon from "mdi-react/PlusIcon";
import { grey } from "@material-ui/core/colors";
import useScreensize from "../../hooks/useScreensize";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  titleBox: {
    background: "rgba(255,255,255,0.4)",
    display: "inline-block",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    color: "white"
  },
  btnIcon: {
    marginRight: theme.spacing(2)
  },
  paragraph: {
    color: grey[600],
    marginBottom: theme.spacing(2)
  },
  paragraphLight: {
    color: "white",
    marginBottom: theme.spacing(2)
  },
  sliderLayer: {
    pointerEvents: "none"
  },
  sliderText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem"
    }
  }
}));

const mainSliderHeights = {
  xs: 400,
  sm: 400,
  md: 400,
  lg: 500,
  xl: 500
};

const Home = ({ refs }) => {
  const [slider, setSlider] = useState(null);
  const [termin, setTermin] = useState(null);
  const [sections, setSections] = useState(null);
  const [impressionen, setImpressionen] = useState(null);
  const classes = useStyles();
  const { xs, sm, md, lg, xl, current } = useScreensize();
  const theme = useTheme();

  useEffect(() => {
    fetchSlider();
    fetchTermin();
    fetchSections();
    fetchImpressionen();
  }, []);

  const fetchSlider = () => {
    client
      .items()
      .type("slider")
      .toObservable()
      .subscribe(response => {
        setSlider(response.items ? response.items : null);
      });
  };

  const fetchImpressionen = () => {
    client
      .items()
      .type("impressionen")
      .equalsFilter("elements.identifier", "impressionen-startseite")
      .toObservable()
      .subscribe(response => {
        setImpressionen(response.items ? response.items[0] : null);
      });
  };

  const fetchSections = () => {
    client
      .items()
      .type("section")
      .toObservable()
      .subscribe(response => {
        setSections(response.items ? response.items : null);
      });
  };

  const fetchTermin = () => {
    client
      .items()
      .type("termin")
      .limitParameter(1)
      .greaterThanOrEqualFilter("elements.datum", moment().format("YYYY-MM-DD"))
      .orderParameter("elements.datum", SortOrder.asc)
      .toObservable()
      .subscribe(response => {
        setTermin(response.items ? response.items[0] : null);
      });
  };

  const replaceMapping = [
    {
      tag: "p",
      variant: "body1",
      component: "p",
      className: classes.paragraph
    },
    { tag: "h1", variant: "h3", component: "h1", className: "" },
    { tag: "h2", variant: "h4", component: "h2", className: "" },
    { tag: "h3", variant: "h5", component: "h3", className: "" },
    { tag: "h4", variant: "h6", component: "h4", className: "" }
  ];

  const replaceMappingLight = [
    {
      tag: "p",
      variant: "body1",
      component: "p",
      className: classes.paragraphLight
    },
    { tag: "h1", variant: "h3", component: "h1", className: "" },
    { tag: "h2", variant: "h4", component: "h2", className: "" },
    { tag: "h3", variant: "h5", component: "h3", className: "" },
    { tag: "h4", variant: "h6", component: "h4", className: "" }
  ];

  const sliderMain =
    (Boolean(slider) &&
      slider.filter(f => f.identifier.value === "Hauptslider")[0]) ||
    null;

  const sectionWerWirSind =
    (Boolean(sections) &&
      sections.filter(f => f.identifier.value === "wer-wir-sind")[0]) ||
    null;

  const sectionWasWirTun =
    (Boolean(sections) &&
      sections.filter(f => f.identifier.value === "was-wir-tun")[0]) ||
    null;

  // console.log(termin, moment().format("YYYY-MM-DD"));

  return (
    <>
      {Boolean(sliderMain) && (
        <Box position="relative">
          <WjSlider
            height={mainSliderHeights[current]}
            images={sliderMain.bilder.value.map(b => b.url)}
          />
          <Box
            left={0}
            right={0}
            top={0}
            bottom={0}
            position="absolute"
            className={classes.sliderLayer}
          >
            <Container maxWidth="lg">
              <Box py={4}>
                <Typography variant="h1" className={classes.sliderText}>
                  <span className={classes.titleBox}>
                    Bessere Führungskräfte
                  </span>
                  <br />
                  <span className={classes.titleBox}>
                    Für eine bessere Gesellschaft
                  </span>
                </Typography>
              </Box>
            </Container>
          </Box>
        </Box>
      )}
      <Box py={{ xs: 3, md: 10 }} ref={refs[0]} id="wer-wir-sind">
        <Container maxWidth="lg">
          <Box display="flex" flexDirection={xs || sm ? "column" : "row"}>
            <Box
              width={{ xs: "auto", md: 400 }}
              flexShrink={0}
              pr={{ xs: 0, md: 4 }}
              mb={{ xs: 5, md: 0 }}
            >
              {Boolean(termin) && <WjTermin item={termin} />}
            </Box>
            <Box flexGrow={1}>
              <Typography variant="h2" color="primary">
                Wer wir sind
              </Typography>
              <Box py={2.5}>
                {Boolean(sectionWerWirSind) && (
                  <WjHtml replaceMapping={replaceMapping}>
                    {sectionWerWirSind.text.value}
                  </WjHtml>
                )}
              </Box>
              <Button color="primary" component={Link} to="/mitglied-werden">
                <PlusIcon className={classes.btnIcon} /> Mitglied werden
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        pt={{ xs: 3, md: 10 }}
        pb={{ xs: 0, md: 10 }}
        bgcolor={theme.palette.primary.main}
        color="white"
        ref={refs[1]}
        id="was-wir-tun"
      >
        <Container maxWidth="lg">
          <Box display="flex" flexDirection={xs || sm ? "column" : "row"}>
            <Box
              flexBasis={xs ? "auto" : "1px"}
              flexGrow={1}
              flexShrink={0}
              mr={{ xs: 0, md: 10 }}
            >
              <Typography variant="h2">Was wir tun</Typography>
              <Box py={3}>
                {Boolean(sectionWasWirTun) && (
                  <WjHtml replaceMapping={replaceMappingLight}>
                    {sectionWasWirTun.text.value}
                  </WjHtml>
                )}
              </Box>
            </Box>
            <Box flexBasis={xs ? "auto" : "1px"} flexGrow={1} flexShrink={0}>
              <Typography variant="h2">Impressionen</Typography>
              <Box pt={3} pb={{ xs: 2, md: 3 }}>
                {Boolean(impressionen) && (
                  <WjSlider
                    height={xs ? 160 : 300}
                    images={impressionen.bilder.value.map(b => b.url)}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Home;
