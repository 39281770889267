import React, { useState } from "react";
import useScreensize from "../../hooks/useScreensize";
import {
  useTheme,
  Box,
  Typography,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import * as constants from "../../constants";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Lightbox from "react-image-lightbox";
import ArrowExpandAllIcon from "mdi-react/ArrowExpandAllIcon";

const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {}
  },
  slide: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    cursor: "pointer"
  },
  btnPrev: {
    position: "absolute",
    left: theme.spacing(0.5),
    top: "50%",
    marginTop: -15,
    color: "white",
    padding: 0
  },
  btnNext: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: "50%",
    marginTop: -15,
    color: "white",
    padding: 0
  },
  btnSliderIcon: {
    width: 50,
    height: 50
  },
  zoomIcon: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "white",
    pointerEvents: "none"
  }
}));

const WjSlider = ({ images, height = 500 }) => {
  const classes = useStyles();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const theme = useTheme();
  const { xs, sm, md, lg, xl, current } = useScreensize();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const onPrev = () => {
    setCurrentSlideIndex(state => state - 1);
  };

  const onNext = () => {
    setCurrentSlideIndex(state => state + 1);
  };

  const onUpdateSlider = event => {
    // console.log(event);
  };

  let calculatedSlideIndex = currentSlideIndex % images.length;
  if (calculatedSlideIndex < 0) {
    calculatedSlideIndex = images.length + calculatedSlideIndex;
  }

  return (
    <>
      <div className={classes.sliderWrapper}>
        <Carousel
          autoPlay={false}
          selectedItem={calculatedSlideIndex}
          onChange={onUpdateSlider}
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          swipeable={true}
          showStatus={false}
        >
          {images.map((image, index) => (
            <div
              onClick={() => {
                setIsOpen(true);
                setCurrentSlideIndex(index);
              }}
              key={index}
              className={classes.slide}
              style={{
                backgroundImage: "url(" + image + ")",
                height: height
              }}
            ></div>
          ))}
        </Carousel>
        <ArrowExpandAllIcon className={classes.zoomIcon} />
        {!xs && (
          <>
            <IconButton className={classes.btnPrev} onClick={onPrev}>
              <ChevronLeftIcon className={classes.btnSliderIcon} />
            </IconButton>
            <IconButton className={classes.btnNext} onClick={onNext}>
              <ChevronRightIcon className={classes.btnSliderIcon} />
            </IconButton>
          </>
        )}
      </div>
      {isOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9999999 } }}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => {
            setIsOpen(false);
          }}
          onMovePrevRequest={() => {
            setPhotoIndex(
              current => (current + images.length - 1) % images.length
            );
          }}
          onMoveNextRequest={() => {
            setPhotoIndex(current => (current + 1) % images.length);
          }}
        />
      )}
    </>
  );
};

export default WjSlider;
