import React from "react";
import { Typography, Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    right: 0,
    bottom: 0,
    width: 50,
    zIndex: 9999999999,
    background: "#eee",
    padding: 4,
    textAlign: "center"
  }
}));

const ScreenSize = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden smUp>
        <Typography variant="overline">xs</Typography>
      </Hidden>
      <Hidden xsDown mdUp>
        <Typography variant="overline">sm</Typography>
      </Hidden>
      <Hidden smDown lgUp>
        <Typography variant="overline">md</Typography>
      </Hidden>
      <Hidden mdDown xlUp>
        <Typography variant="overline">lg</Typography>
      </Hidden>
      <Hidden lgDown>
        <Typography variant="overline">xl</Typography>
      </Hidden>
    </div>
  );
};

export default ScreenSize;
